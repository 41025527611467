<template>
  <v-app>
    <vue-particles
      color="#240b36"
      :particleOpacity="0.7"
      :particlesNumber="50"
      shapeType="star"
      :particleSize="4"
      linesColor="#c31432"
      :linesWidth="2"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    </vue-particles>
    <v-container class="container">
      <v-card class="grid" :elevation="11" width="95%">
        <v-row align="center" class="d-flex align-center">
          <v-col :cols="4" align="center">
            <v-img
              src="../assets/images/logo.png"
              class="logo"
              alt="kereta-sewa-seremban"
            />
          </v-col>
        </v-row>
        <v-row align="center" class="d-flex align-center">
          <v-col :cols="4" align="center">
            <v-btn
              class="btnWa"
              @click="$router.push({ path: '/' })"
              ><v-icon
                style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
              >
                mdi-home-circle-outline mdi-18px </v-icon
              ><v-card-text
                class="text-center header2"
                >Home</v-card-text></v-btn
            >
          </v-col>
        </v-row>
        <v-form ref="form" @submit.prevent="register">
          <v-card-text class="d-flex align-center justify-center about"
            >LOGIN / REGISTER</v-card-text
          >
          <v-row class="d-flex align-center justify-center">
            <v-col cols="12" md="4">
              <v-card-text class="text">Email</v-card-text>
              <v-text-field
                class="text"
                dark
                prepend-icon="mdi-email-outline"
                color="#000"
                v-model="email"
                :rules="emailRules"
                dense
                solo
                data-vv-name="email"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center justify-center about">
            <v-col cols="12" md="4">
              <v-card-text class="text">Password</v-card-text>
              <v-text-field
                class="text"
                prepend-icon="mdi-lock-outline"
                dark
                color="#000"
                v-model="password"
                :rules="[rules.required]"
                maxlength="20"
                dense
                solo
                data-vv-name="password"
                :append-icon="value ? 'visibility' : 'visibility_off'"
                @click:append="() => (value = !value)"
                :type="value ? 'password' : 'text'"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center justify-center">
            <v-col cols="12" md="4">
              <v-card-text
                style="
                  background-image: linear-gradient(to right, #ad5389, #3c1053);
                  -webkit-background-clip: text !important;
                  -webkit-text-fill-color: transparent !important;
                  font-weight: 900 !important ;
                  margin-bottom: 3% !important;
                  padding-top: 0 !important;
                "
                @click="$router.push({ path: '/reset' })"
                >Forgot Password?</v-card-text
              >
            </v-col>
          </v-row>
          <v-row class="justify-center align-center" no-gutters>
            <v-btn class="btnWa" @click="login" :disabled="!canSubmit">
              <v-icon
                style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
              >
                mdi-login mdi-18px
              </v-icon>
              <v-card-text
                class="text-center header2"
                >Login</v-card-text>
            </v-btn>
          </v-row>
          <v-row class="justify-center align-center" no-gutters>
            <v-btn class="btnWa" @click="register">
              <v-icon
                style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
              >
                mdi-account-plus-outline mdi-18px
              </v-icon>
              <v-card-text
                class="text-center header2"
                >First Time User?</v-card-text>
            </v-btn>
          </v-row>
        </v-form>
      </v-card>
      <v-footer padless class="footer" color="transparent">
      <v-card-text class="d-flex flex-column align-top footer">
        <div class="footer-top mb-3">
          <v-row align="center" class="row2">
            <v-col>
              <span
                class="mr-3 d-grid align-center justify-center"
                style="text-align: justify"
              >
                <a
                  href="https://jrvservices.co/"
                  target="_blank"
                  class="text2 text-decoration-none"
                >
                  <v-card-text class="d-flex align-center justify-center text2"
                    >JRV Car Rental Seremban | Kereta Sewa Seremban JRV</v-card-text
                  >
                </a>
              </span>
            </v-col>
          </v-row>
          <v-row align="center" class="row2">
            <v-col>
              <a
                href="https://www.facebook.com/profile.php?id=100095115500120"
                target="_blank"
              >
                <v-icon
                  size="32"
                  class="mx-2 btnWa"
                  style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                  @click="trackButtonClick('FacebookGroup')"
                  >mdi-facebook</v-icon
                >
              </a>
              <a
                href="https://www.instagram.com/jrv_keretasewa/"
                target="_blank"
              >
                <v-icon
                  size="32"
                  class="mx-2 btnWa"
                  style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                  @click="trackButtonClick('InstagramGroup')"
                  >mdi-instagram</v-icon
                >
              </a>
              <a
                href="https://chat.whatsapp.com/DVZXvnBiDqS0Z9jkHwL9yT"
                target="_blank"
              >
                <v-icon
                  size="32"
                  class="mx-2 btnWa"
                  style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                  @click="trackButtonClick('WhatsappGroup')"
                  >mdi-whatsapp</v-icon
                >
              </a>
              <a href="https://t.me/+ILGvKb39imRkMDNl" target="_blank">
                <v-icon
                  size="32"
                  class="mx-2 btnWa"
                  style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                  @click="trackButtonClick('TelegramGroup')"
                  >mdi-telegram</v-icon
                >
              </a>
            </v-col>
          </v-row>
        </div>
        <div
          class="footer-bottom text2"
          style="margin-top: 1% !important ; margin-bottom: 1% !important"
        >
          Copyright {{ new Date().getFullYear() }} @
          <a
            href="https://www.linkedin.com/in/rajveenz2/"
            target="_blank"
            class="company-link"
            style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
            >JRV GLOBAL SERVICES</a
          >
        </div>
      </v-card-text>
    </v-footer>
    </v-container>
  </v-app>
</template>

<script>
import DataService from "../services/dataService.js";
const dataService = new DataService();

export default {
  data() {
    return {
      email: null,
      password: null,
      value: String,
      valid: true,
      rules: {
        required: (value) => !!value || "Password is required!",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
      emailRules: [
        (v) => !!v || "E-mail is required!",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid!",
      ],
      errorMessage: false,
    };
  },

  computed: {
    canSubmit() {
      return this.email && this.password;
    },
  },

  mounted() {
    this.errorMessage = "Failed";
  },

  methods: {
    trackButtonClick(a) {
      console.log(a);
      this.$gtag.event("Button_clicks", {
        event_category: a,
        event_label: "User clicked on icon",
      });
    },

    async register() {
      this.$router.push({ path: `/register` });
    },
    async login() {
      try {
        this.$setLoader();
        let loginDto = {
          email: this.email.toLowerCase(),
          password: this.password,
        };
        await dataService.login(loginDto).then((res) => {
          this.$store.state.user = res.data.user;
          window.$cookies.set("posToken", res.data.token);
          let role = res.data.user.roles;
          let superAdmin = role.find((element) => element == "admin");
          console.log(superAdmin);
          if (superAdmin == "admin") {
            this.$store.state.loggedInAs = "ADMIN";
            this.$router.push({ path: `/home` });
          } else {
            this.$store.state.loggedInAs = "USER";
            this.$router.push({ path: `/` });
          }
        });
        this.$disableLoader();
      } catch (error) {
        // alert("Error 1000 - Page does not exists!")
      }
      // this.$setLoader();
      // this.$router.push({ path: `/home` });
      // this.$disableLoader();
    },
  },
};
</script>

<style scoped lang="css">
.container {
  /* margin-top: 5%; */
  max-width: none !important;
}
</style>
